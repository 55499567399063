var lastPopup;
popupCenter = function(url, title, w, h) {
    // Fixes dual-screen position
    var dualScreenLeft = typeof window.screenLeft != 'undefined' && window.screenLeft ? window.screenLeft : screen.left;
    var dualScreenTop = typeof window.screenTop != 'undefined' && window.screenTop ? window.screenTop : screen.top;

    var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

    var left = 50;((width / 2) - (w / 2)) + dualScreenLeft;
    var top = 50;((height / 2) - (h / 2)) + dualScreenTop;
    var pref = 'directories=no,titlebar=no,toolbar=yes,location=no,status=no,menubar=no,scrollbars=yes,width=' + w + ',height=' + h + ',top=' + top + ',left=' + left;

    var newWindow = window.open(url, 'dss-window', pref);

    if (window.focus) {newWindow.focus()}
    lastPopup = newWindow;
};

setHasOnPopup = function(hash){
    if(lastPopup){

        var url = lastPopup.location.href;
        console.log(lastPopup.location.href);
        lastPopup.location.href = url+hash;
    }
}

function updateQueryStringParameter(uri, key, value) {
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
        return uri.replace(re, '$1' + key + "=" + value + '$2');
    }
    else {
        return uri + separator + key + "=" + value;
    }
}
expander = function($this, id){
    var $el = $($this).hasClass("fa") ? $($this) : $($this).parent().find("[class*='fa-caret-square-o-']");
    $el.toggleClass('fa-caret-square-o-down').toggleClass('fa-caret-square-o-up');
    $('#'+id).toggleClass('hidden');
};

$( document ).ready(function() {
    var audioSet = false;
    textToSpeach = function(text){
        if(audioSet == true){
            return 'loaded';
        }
        $.getJSON("https://vaas.acapela-group.com/Services/UrlMaker?jsoncallback=?", {
                prot_vers: 2, cl_login: "Dansk_Skoleskak",
                cl_app: "Dansk_Skoleskak_app",
                cl_pwd: "80f7g4f339",
                req_voice:"mette22k",
                req_text:text
                //to produce ogg vorbis files, for MP3 you can remove this param.
                //req_snd_type:"OGG"
            },
            function(data){
                // Data exploitation
                var audio = document.getElementById('audioTag');
                audioSet = true;
                audio.src = data.snd_url;
                audio.load();
                audio.play();

                var $audioLink = $('#audioLink');
                $audioLink.find('#volume').removeClass('hidden');
                $audioLink.find('#spinner').addClass('hidden');

            }
        );
    }
});

// AI settings
var g_ply = 6;
var g_timeout = 10000;

var g_playerWhite = true;
var g_startOffset = null;
var g_selectedPiece = null;
var g_allMoves = [];
var g_changingFen = false;
var g_analyzing = false;
var g_uiBoard;
var g_cellSize = 45;
var g_vectorDelta = [];
// AI settings end

aiGetMoves = function (aiParams, returnFunction){
    ResetGame();
    aiParams.g_ply = typeof aiParams.g_ply != 'undefined' ? aiParams.g_ply : 5;
    InitializeFromFen(aiParams.fen+' '+aiParams.colorToMove+' w - -');
    Search(returnFunction, aiParams.g_ply, null);
};

var langPieceMappings = {
    'k': {'dk': 'konge'},
    'q': {'dk': 'dronning'},
    'b': {'dk': 'løber'},
    'n': {'dk': 'springer'},
    'r': {'dk': 'tårn'},
    'p': {'dk': 'bonde'}
};

transLateMoves = function(str){
    if(typeof str !== 'undefined' || !str){
        str = str.replace("#", "++").trim();
        var oldFirstLetter = str[0];
        var newFirstLetter = langPieceMappings[oldFirstLetter.toLowerCase()] ? (oldFirstLetter.toUpperCase() == oldFirstLetter ? langPieceMappings[oldFirstLetter.toLowerCase()].dk[0].toUpperCase() : langPieceMappings[oldFirstLetter.toLowerCase()].dk[0]) : oldFirstLetter;
        $.each(langPieceMappings, function( key, value ) {
            str = str.replace(new RegExp(" "+key, 'g'), " "+value.dk[0]).replace(new RegExp(" "+(key.toUpperCase()), 'g'), " "+value.dk[0].toUpperCase()).trim();
        });

        if(oldFirstLetter !== newFirstLetter){
            str = str.replace(oldFirstLetter,newFirstLetter);
        }
        return str.replace(/\s/g, ", ");
    }
    return '';
};
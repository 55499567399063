$( document ).ready(function() {
    var $cycleImages = $('#cycleImages');
    if($cycleImages[0]){
        var imgNr = parseInt($cycleImages.data('img-nr'));
        var $oldImg = null;
        var images = $cycleImages.data('cycle-images');
        function changeBackground(){
            var oldImgNr = imgNr;
            imgNr = imgNr+1 <= images.length ? imgNr+1 : 1;
            $oldImg = $cycleImages.find('#img_'+oldImgNr);
            var $newImg = $cycleImages.find('#img_'+imgNr);
            $newImg.css({'z-index':'2'}).fadeIn( 2000, function(){
                $(this).css({'z-index':'1'});
                $oldImg.css({'z-index':'0'}).hide();
            });
        }
        if(images instanceof Array){
            var path = '/assets/img/home-images/';
            var imgLoaded = 1;
            for(var i=0; i < images.length; i++){
                if(i+1 != imgNr){
                    $('<img src="'+ path + images[i].img +'" class="cycle-img" id="img_'+(i+1)+'">').load(function() {
                        $(this).hide().appendTo($cycleImages);
                        imgLoaded++;
                        if(imgLoaded == images.length){
                            setInterval(changeBackground, 6000);
                        }
                    });
                }
            }
        }
    }
});
